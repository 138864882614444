.modal-content {
    max-width: 800px;
    width: 90%;
  }
  
  .close-button {
    background: transparent;
    border: none;
    color: #444444;
    font-size: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
  
  .modal-title {
    color: #444444;
    font-size: 24px;
    margin-bottom: 16px;
  }
  