@font-face {
  font-family: "Futura";
  src: url("../../assets/FUTURA/Futura_Bold_Italic_font.ttf") format("truetype");
}

.App {
  font-family: Futura;
}

.intel-icon::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-image: url("../../assets/img/Logos_Intelectronica.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* color:#00c4f2; */
  /* background-color: #00c4f2; */
  /* Additional styling properties */
}
.menu-icon {
  background-color: #00c4f2;
}

/* --- */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 20vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #020728;
  font-size: 70px;
}

.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #020728;
  font-size: 70px;
}

.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #020728;
  font-size: 100px; 
}


.box {
  max-width: 800px; /* Adjust the width as needed */
  padding: 20px;
}

.slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-container img {
  width: 100%;
  height: auto;
  border-radius: 0.5rem; /* Tailwind's 'rounded-lg' equivalent */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Tailwind's 'shadow-lg' equivalent */
  transition: transform 0.3s ease-in-out;
}

.carousel-container img:hover {
  transform: scale(1.05); /* Tailwind's 'hover:scale-105' equivalent */
}


